import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../utils/seo"
import richText from "../utils/richText"

const Intranet = ({ data }) => {
  const { nom, contingut, password } = data.contentfulIntranet
  const [inpass, setInpass] = useState("")
  const [passError, setPassError] = useState(false)

  const [isPasswordSet, setIsPasswordSet] = useState(false)
  const handleInputSubmit = e => {
    e.preventDefault()
    if (inpass === password) {
      setPassError(false)
      setIsPasswordSet(true)
    } else {
      setPassError(true)
    }
  }
  if (!isPasswordSet)
    return (
      <Layout title={nom}>
        <SEO title={nom} />
        <div className="container">
          <br />
          <br />
          <form style={{ width: 400, margin: 80}}>
          <label>
            Contrasenya:
            <br/>
            <br/>
            <input
              value={inpass}
              onChange={e => setInpass(e.target.value)}
            ></input>
          </label>
            <br/>
            <br/>
            <br/>
            <button type="submit" onClick={handleInputSubmit}>
              Accedeix
            </button>
            {passError && <p>Contrassenya incorrecte.</p>}
          </form>
        </div>
      </Layout>
    )

  const descripcio = richText(contingut.json)
  return (
    <Layout title={nom}>
      <SEO title={nom} />
      <div className="container">
        <br />
        <br />
        <div className="descripcio">{descripcio}</div>
      </div>
    </Layout>
  )
}
export default Intranet
export const pageQuery = graphql`
  query($slug: String!) {
    contentfulIntranet(slug: { eq: $slug }) {
      id
      nom
      password
      slug
      contingut {
        json
      }
    }
  }
`
